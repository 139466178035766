export function verifyStateTaxData(stateTaxDataArray) {
    // Define the fields with their expected types/formats
    const requiredFields = {
        state: 'string',
        incomeTaxBracketsSingle: 'bracketOrNA',
        incomeTaxBracketsMarriedFilingJointly: 'bracketOrNA',
        individualIncomeTaxRatesWagesSingle: 'rate',
        individualIncomeTaxRatesWagesMarriedFilingJointly: 'rate',
        incomeTaxPersonalExemptionSingle: 'numberOrNA',
        incomeTaxPersonalExemptionMarriedFilingJointly: 'numberOrNA',
        incomeTaxPersonalExemptionCreditSingle: 'numberOrNA',
        incomeTaxPersonalExemptionCreditMarriedFilingJointly: 'numberOrNA',
        standardDeductionSingle: 'numberOrNA',
        standardDeductionMarriedFilingJointly: 'numberOrNA',
        federalTaxesDeductible: 'yesNoOrNA',
        socialSecurityIncomeExempted: 'yesNoOrNumber',
        traditionalIra401kDistributionExemptionSingle: 'numberOrAll',
        traditionalIra401kDistributionExemptionMarried: 'numberOrAll',
        capitalGainsTaxRate: 'rateOrIncluded',
        interestAndDividendsTaxRate: 'rateOrIncluded',
        generalSalesTaxRate: 'rate',
        groceriesSalesTaxRate: 'rate',
        estateTaxExemption: 'numberOrNA',
        estateTaxBrackets: 'bracketOrNA',
        estateTaxRates: 'rateOrBracketOrNA',
        estatePortability: 'yesNoOrNA',
        notes: 'string'
    };

    const errors = [];

    stateTaxDataArray.forEach((stateData, index) => {
        const stateName = stateData.state || `State at index ${index}`;

        // Check if all required fields are present and of the correct type
        for (const [key, expectedType] of Object.entries(requiredFields)) {
            if (!(key in stateData)) {
                errors.push(`${stateName}: Missing required field "${key}".`);
                continue;
            }

            const value = stateData[key];
            if (!validateField(value, expectedType) && !expectedError(stateName, key)) {
                errors.push(`${stateName}: Field "${key}" has an invalid value "${value}" for expected type "${expectedType}".`);
                
            }

            // Additional checks for consistency
            if (key === 'individualIncomeTaxRatesWagesSingle') {
                const brackets = stateData.incomeTaxBracketsSingle.split(',').filter(val => val !== "").map(Number);
                const rates = stateData.individualIncomeTaxRatesWagesSingle.split(',').map(Number);

                // Check if brackets length is one less than rates, except for specific cases
                if (brackets.length !== rates.length - 1) {
                    // Allow if the brackets are empty or contain only "0" and the rate length is 1
                    if (!(brackets.length === 0 && rates.length === 1) && !(brackets.length === 1 && brackets[0] === 0 && rates.length === 1)) {
                        errors.push(`${stateName}: The length of brackets (${brackets.length}) should be one less than rates (${rates.length}), unless the brackets are empty or contain only "0" with a single rate.`);
                    }
                }
            }

            if(key === 'individualIncomeTaxRatesWagesMarriedFilingJointly') {
                const brackets = stateData.incomeTaxBracketsMarriedFilingJointly.split(',').filter(val => val !== "").map(Number);
                const rates = stateData.individualIncomeTaxRatesWagesMarriedFilingJointly.split(',').map(Number);

                // Check if brackets length is one less than rates, except for specific cases
                if (brackets.length !== rates.length - 1) {
                    // Allow if the brackets are empty or contain only "0" and the rate length is 1
                    if (!(brackets.length === 0 && rates.length === 1) && !(brackets.length === 1 && brackets[0] === 0 && rates.length === 1)) {
                        errors.push(`${stateName}: The length of brackets (${brackets.length}) should be one less than rates (${rates.length}), unless the brackets are empty or contain only "0" with a single rate.`);
                    }
                }
            }

            // Check cases where there's a single bracket, expecting exactly two rates
            if ((key === 'incomeTaxBracketsSingle' || key === 'incomeTaxBracketsMarriedFilingJointly') && value !== 'na') {
                const brackets = value.split(',').filter(val => val !== "").map(Number);
                const rawRates = stateData.individualIncomeTaxRatesWagesSingle;
                const rates = rawRates.split(',').map(Number);

                if (brackets.length === 1 && rates.length !== 2) {
                    errors.push(`${stateName}: Single bracket case requires exactly two rates, but found ${rates.length}.`);
                }
            }
        }
    });

    // Function to validate field values based on expected types/formats
    function validateField(value, expectedType) {
        switch (expectedType) {
            case 'string':
                return typeof value === 'string';
            case 'number':
                return !isNaN(Number(value)) && value !== '';
            case 'numberOrNA':
                return !isNaN(Number(value)) || value.toLowerCase() === 'na';
            case 'bracket':
                return value.split(',').every(val => !isNaN(Number(val)));
            case 'bracketOrNA':
                return value.toLowerCase() === 'na' || value.split(',').every(val => !isNaN(Number(val)));
            case 'rate':
                return value.split(',').every(val => !isNaN(Number(val)));
            case 'rateOrIncluded':
                return value.toLowerCase() === 'included in income' || value.split(',').every(val => !isNaN(Number(val)));
            case 'rateOrBracketOrNA':
                return value.toLowerCase() === 'na' || value.split(',').every(val => !isNaN(Number(val)));
            case 'yesNo':
                return ['yes', 'no'].includes(value.toLowerCase());
            case 'yesNoOrNA':
                return ['yes', 'no', 'na'].includes(value.toLowerCase());
            case 'yesNoOrNumber':
                return ['yes', 'no'].includes(value.toLowerCase()) || !isNaN(Number(value));
            case 'numberOrAll':
                return !isNaN(Number(value)) || value.toLowerCase() === 'all';
            default:
                return false;
        }
    }

    // Remove some expected errors.
    function expectedError(stateName, key) {
        switch (stateName) {
            case 'CONNECTICUT':
                return key === 'traditionalIra401kDistributionExemptionSingle' || key === 'traditionalIra401kDistributionExemptionMarried';
            default:
                return false;
        }
    }

    // Log or return the verification results
    if (errors.length > 0) {
        console.error(`Verification completed with ${errors.length} issues found:`);
        errors.forEach((error) => console.error(error));
    } else {
        console.log("All state tax data entries passed verification.");
    }
}
