// !!! (Incomplete) !!! 
function calculateFederalTaxes(submittedData) {
    return 0;
}

// for Connecticut 2024 tax numbers
function calculateConnecticutRetirementExemption(submittedData) {
    const { retirementIncome, maritalStatus, laborIncome, capitalGains, socialSecurity, interestDividends } = submittedData;

    // Calculate Federal AGI (adjusted gross income)
    let federalAGI = Number(laborIncome) + Number(capitalGains) + Number(socialSecurity) + Number(interestDividends) + Number(retirementIncome);

    // Hardcoded deduction schedule for Connecticut based on marital status
    const deductionScheduleSingle = [
        { maxAGI: 75000, percentage: 100.0 },
        { maxAGI: 77499, percentage: 85.0 },
        { maxAGI: 79999, percentage: 70.0 },
        { maxAGI: 82499, percentage: 55.0 },
        { maxAGI: 84999, percentage: 40.0 },
        { maxAGI: 87499, percentage: 25.0 },
        { maxAGI: 89999, percentage: 10.0 },
        { maxAGI: 94999, percentage: 5.0 },
        { maxAGI: 99999, percentage: 2.5 },
        { maxAGI: Infinity, percentage: 0.0 }
    ];

    const deductionScheduleMarried = [
        { maxAGI: 100000, percentage: 100.0 },
        { maxAGI: 104999, percentage: 85.0 },
        { maxAGI: 109999, percentage: 70.0 },
        { maxAGI: 114999, percentage: 55.0 },
        { maxAGI: 119999, percentage: 40.0 },
        { maxAGI: 124999, percentage: 25.0 },
        { maxAGI: 129999, percentage: 10.0 },
        { maxAGI: 139999, percentage: 5.0 },
        { maxAGI: 149999, percentage: 2.5 },
        { maxAGI: Infinity, percentage: 0.0 }
    ];

    // Choose the correct schedule based on marital status
    const schedule = maritalStatus === 'married' ? deductionScheduleMarried : deductionScheduleSingle;

    // Determine the applicable deduction percentage based on the federal AGI
    let applicableDeduction = 0;
    for (let i = 0; i < schedule.length; i++) {
        if (federalAGI <= schedule[i].maxAGI) {
            applicableDeduction = schedule[i].percentage;
            break;
        }
    }

    // Calculate the amount of exempted retirement income
    const exemptedRetirementIncome = retirementIncome * (applicableDeduction / 100);

    // Return the exempted amount
    return exemptedRetirementIncome;
}

export function calculateIncomeTax(submittedData, stateTaxDataJson) {

    let totalIncome = Number(submittedData.laborIncome);
    let totalIncomeTaxes = 0;

    // 1. Determine filing status
    const isMarried = submittedData.maritalStatus === 'married';

    // 2. Set exemptions and standard deductions
    const personalExemptionCredit = isMarried ?
        stateTaxDataJson.incomeTaxPersonalExemptionCreditMarriedFilingJointly : stateTaxDataJson.incomeTaxPersonalExemptionCreditSingle;
    const personalExemption = isMarried ?
        stateTaxDataJson.incomeTaxPersonalExemptionMarriedFilingJointly : stateTaxDataJson.incomeTaxPersonalExemptionSingle;
    const standardDeduction = isMarried ?
        stateTaxDataJson.standardDeductionMarriedFilingJointly : stateTaxDataJson.standardDeductionSingle;
    const deductionAndExemption = Number(personalExemption) + Number(standardDeduction);
    const iraExemption = isMarried ?
        stateTaxDataJson.traditionalIra401kDistributionExemptionMarried : stateTaxDataJson.traditionalIra401kDistributionExemptionSingle;
    const privateRetirementIncomeAllExempted = iraExemption === "all";
    const federalTaxesDeductible = stateTaxDataJson.federalTaxesDeductible === "yes";
    const capitalGainsIncludedInIncome = stateTaxDataJson.capitalGainsTaxRate.includes("included in income");
    const interestAndDividendsIncludedInIncome = stateTaxDataJson.interestAndDividendsTaxRate.toLowerCase().includes("included in income");
    const brackets = isMarried ? stateTaxDataJson.incomeTaxBracketsMarriedFilingJointly : stateTaxDataJson.incomeTaxBracketsSingle;
    const rates = isMarried ?
        stateTaxDataJson.individualIncomeTaxRatesWagesMarriedFilingJointly : stateTaxDataJson.individualIncomeTaxRatesWagesSingle;

    // Check if income tax is applicable and handle missing or 0 values
    const noIndividualIncomeTax = rates === "0";
    const noCapitalGainsTax = stateTaxDataJson.capitalGainsTaxRate === "0";
    const noInterestAndDividendsTax = stateTaxDataJson.interestAndDividendsTaxRate === "0";

    if (noIndividualIncomeTax && noCapitalGainsTax && noInterestAndDividendsTax) {
        return 0; // Return 0 if there's no applicable income tax
    }

    // 3. Simpler case of no individual income tax
    if (noIndividualIncomeTax) {
        if (!noCapitalGainsTax) {
            const capitalGainsTaxableIncome = Math.max(Number(submittedData.capitalGains) - Number(stateTaxDataJson.capitalGainsTaxExemption), 0);
            totalIncomeTaxes += capitalGainsTaxableIncome * Number(stateTaxDataJson.capitalGainsTaxRate);
        }
        if (!noInterestAndDividendsTax) {
            const interestAndDividendsTaxableIncome = Math.max(Number(submittedData.interestDividends) - deductionAndExemption, 0);
            totalIncomeTaxes += interestAndDividendsTaxableIncome * Number(stateTaxDataJson.interestAndDividendsTaxRate);

        }
        return totalIncomeTaxes;
    }

    // 4. Determine Social Security income / taxes
    const socialSecurityExemptionAmount = stateTaxDataJson.socialSecurityIncomeExempted === "yes"
        ? Number(submittedData.socialSecurity)
        : stateTaxDataJson.socialSecurityIncomeExempted === "no"
            ? 0
            : Math.min(Number(stateTaxDataJson.socialSecurityIncomeExempted), Number(submittedData.socialSecurity));

    totalIncome += Math.max(Number(submittedData.socialSecurity) - socialSecurityExemptionAmount, 0);

    // 5. Determine Capital Gains taxes
    if (capitalGainsIncludedInIncome) {
        totalIncome += Number(submittedData.capitalGains);
    } else {
        totalIncomeTaxes += Number(stateTaxDataJson.capitalGainsTaxRate) * Number(submittedData.capitalGains);
    }

    // 6. Determine Interest and dividends taxes
    if (interestAndDividendsIncludedInIncome) {
        totalIncome += Number(submittedData.interestDividends);
    } else {
        totalIncomeTaxes += Number(stateTaxDataJson.interestAndDividendsTaxRate) * Number(submittedData.interestDividends);
    }

    // 7. Determine Private Retirement Income and taxes
    if (!privateRetirementIncomeAllExempted) {
        let retirementIncome = Number(submittedData.retirementIncome);

        if (stateTaxDataJson.state === "CONNECTICUT") {
            // Apply Connecticut-specific retirement exemption
            const connecticutExemption = calculateConnecticutRetirementExemption(submittedData);
            retirementIncome -= connecticutExemption;
        } else {
            // Apply the general IRA/401k exemption
            retirementIncome -= Number(iraExemption);
        }

        // Ensure retirementIncome does not go below zero
        totalIncome += retirementIncome > 0 ? retirementIncome : 0;
    }

    // !!! (Incomplete) !!! 
    // Not doing this. Justification "TCJA made itemization pointless for the vast majority of households"
    // 8. Determine Federal Tax Deducation
    if (federalTaxesDeductible) {
        totalIncome -= calculateFederalTaxes(submittedData);
    }

    // !!! (Incomplete) !!! 
    // Need to know how to choose which one to apply.
    // 9. Apply personal exemption or standard deduction
    totalIncome -= Number(personalExemption) + Number(standardDeduction);

    // 10. Simple income tax cases of no brackets
    if (!brackets) {
        totalIncomeTaxes += (Number(rates) * totalIncome);
    }
    // 11. Handle Tax Bracket cases
    else {
        const bracketArray = brackets.split(',').map(Number);
        const rateArray = rates.split(',').map(Number);

        for (let i = 0; i < rateArray.length; i++) {
            const previousBracket = i > 0 ? bracketArray[i - 1] : 0;

            if (i < bracketArray.length && totalIncome > bracketArray[i]) {
                // Calculate tax for the entire portion in this bracket
                const incomeInBracket = bracketArray[i] - previousBracket;
                totalIncomeTaxes += incomeInBracket * rateArray[i];
            } else {
                // Calculate tax for the remaining income
                const incomeInBracket = totalIncome - previousBracket;
                totalIncomeTaxes += incomeInBracket * rateArray[i];
                break; // No need to calculate further as all remaining income is taxed
            }
        }

        // If the income exceeds all brackets, apply the highest rate
        if (totalIncome > bracketArray[bracketArray.length - 1]) {
            const incomeAboveLastBracket = totalIncome - bracketArray[bracketArray.length - 1];
            totalIncomeTaxes += incomeAboveLastBracket * rateArray[rateArray.length - 1];
        }
    }

    totalIncomeTaxes -= Number(personalExemptionCredit);
    return totalIncomeTaxes > 0 ? totalIncomeTaxes : 0;
}

export function calculateSalesTax(submittedData, stateTaxDataJson) {
    const monthlyGroceryTaxes = submittedData.groceryExpenses * Number(stateTaxDataJson.groceriesSalesTaxRate);
    const monthlySalesTaxes = submittedData.retailPurchases * Number(stateTaxDataJson.generalSalesTaxRate);
    const totalMonthlyTaxes = (monthlyGroceryTaxes > 0 ? monthlyGroceryTaxes : 0) + (monthlySalesTaxes > 0 ? monthlySalesTaxes : 0)
    const totalYearlyTaxes = totalMonthlyTaxes * 12;
    return totalYearlyTaxes;
}

export function calculateEstateTax(submittedData, stateTaxDataJson) {
    let estateTax = 0;
    if (stateTaxDataJson.estateTaxRates === "0") {
        return estateTax;
    }
    const taxBrackets = stateTaxDataJson.estateTaxBrackets.split(',').map(Number); // Convert to an array of numbers
    const taxRates = stateTaxDataJson.estateTaxRates.split(',').map(Number); // Convert to an array of decimal rates
    const estateExemption = Number(stateTaxDataJson.estateTaxExemption); // Convert exemption to a number

    // Calculate taxable estate
    let taxableEstate = submittedData.estateValue - estateExemption;
    if (taxableEstate <= 0) return 0; // No taxes if the estate is smaller than the exemption

    if (taxRates.length === 1) {
        estateTax = taxRates[0] * taxableEstate;
        return estateTax > 0 ? estateTax : 0;
    }

    // Iterate through each bracket and apply the corresponding rate
    for (let i = 0; i < taxBrackets.length; i++) {
        if (taxableEstate > taxBrackets[i]) {
            // Calculate the portion of the estate in the current bracket
            let previousBracket = i > 0 ? taxBrackets[i - 1] : 0;
            let taxableInBracket = Math.min(taxableEstate, taxBrackets[i]) - previousBracket;
            estateTax += taxableInBracket * taxRates[i];
        } else {
            // Once the estate is smaller than the current bracket, calculate tax for the remaining amount
            let previousBracket = i > 0 ? taxBrackets[i - 1] : 0;
            estateTax += (taxableEstate - previousBracket) * taxRates[i];
            return estateTax; // Exit early once the taxable amount is fully handled
        }
    }

    // If there’s any remaining taxable estate above the highest bracket, apply the last rate
    if (taxableEstate > taxBrackets[taxBrackets.length - 1]) {
        let remainingTaxableEstate = taxableEstate - taxBrackets[taxBrackets.length - 1];
        estateTax += remainingTaxableEstate * taxRates[taxRates.length - 1];
    }

    return estateTax;
}

export function calculateTotalTaxBurden(submittedData, stateTaxDataJson) {
    let totalTaxes = 0;
    const annualTaxes = calculateIncomeTax(submittedData, stateTaxDataJson) + calculateSalesTax(submittedData, stateTaxDataJson);
    const estateTaxes = calculateEstateTax(submittedData, stateTaxDataJson);
    totalTaxes = annualTaxes + (estateTaxes * submittedData.estateWeighting);
    return totalTaxes;
}
